import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Backdrop, Box } from "@material-ui/core";
import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import Spacer from "../../components/Layout/Spacer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import BaseText from "../../components/Text/BaseText";
import "./Roadmap.css";
import { useState, useEffect } from 'react';
import Grid from '@react-css/grid'
export default function RoadMap() {
  const hasWindow = typeof window !== 'undefined';
  const launchdate = new Date(2021,4,8);
  const today = new Date();
  const days = today-launchdate;
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
if (windowDimensions.width > 1000)
  return (
    <MainSectionContainer
      backgroundColor="#222"
      height={"auto"}
      paddingHorizontal="10vw"
      id="Roadmap"
    >
      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
      >
        Roadmap
      </BaseText>
      <Spacer height="40px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="left" backgroundColor="#222">
        <Spacer height="40px"/>
          <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
            <div className="road-map-description" align="left">  
              <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
                Q2 (2021) - Building the Drakkar & Recruiting the Raid Party
              </BaseText>
              <Spacer height="10px"/>
              <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Token Stealth Launched {Math.ceil(days/(1000*60*60*24))} Days Ago
              </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='10px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Website created
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Use Case Defined
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinMarketCap Listing Applied
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinGecko Listing Applied
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinHunt Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    BlockFolio Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinSniper Listed
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    LiveCoinWatch Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Expanded Development Team
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Partnership with <a href="https://www.moonvalue.info/">moonvalue.info</a>
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Marketing Strategy Developed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Operation Bifrost Defined
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Operation Mirror Mirror Launch
                  </BaseText>
                </Grid>
              </Grid>
            </div>
          </VerticalFlexContainer>
        <Spacer height="525px" backgroundColor="#222"/>
        <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
           Q4 - All Aboard the Drakkar 
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Mass Adoption
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Operation Bifrost Full Launch
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Addition of Services
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Liquidity Pool Addition
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  25% Expansion of Teams 
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Establish Offices in U.S.A and Hungary
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Listings on Exchanges (Bitmart and Whitebit, ZBG, MXC)
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Donations to Charities of Community Choice (funds taken from revenue generated) 
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Operation LowKey Launch
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
        </VerticalFlexContainer>
        <VerticalFlexContainer align="center" paddingHorizontal="50px">
          <div className="road-map-node completed"></div>
          <div className="road-map-line" style={{ height: "450px" }}></div>
          <div className="road-map-node in-progress"></div>
          <div className="road-map-line" style={{ height: "400px" }}></div>
          <div className="road-map-node"></div>
          <div className="road-map-line" style={{ height: "270px" }}></div>
          <div className="road-map-node"></div>{" "}
          <div
            className="road-map-dotted-line"
            style={{ height: "250px" }}
          ></div>
        </VerticalFlexContainer>
        <Spacer width="20px" />
        <VerticalFlexContainer backgroundColor="#222">
          <Spacer height="520px" />
          <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
            Q3 - Loading the Spoils
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Operation Bifrost Unmasked
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                API Data Collection R&D
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Storage and Maintenance R&D
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Live AMA’s Every week
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Celebrity Endorsements
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Marketing for Mass Adoption
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Website Version 2 Launch
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Back End Development for Operation Bifrost
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Listings on Small Exchanges
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
          <Spacer height="350px" />
          <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
          Q1 (2022) - Set Sail For Valhalla
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Grab your Ass...ets 
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Further development of the Assgard Arsenal
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  The Assgard Community will vote on Priorities
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  2022 Roadmap Released 
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
      <Spacer backgroundColor="#222" height="100px" />
    </MainSectionContainer>
  );
  else
  return (
    <MainSectionContainer
      backgroundColor="#222"
      height={"auto"}
      paddingHorizontal="10vw"
      id="Roadmap"
    >
      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
      >
        Roadmap
      </BaseText>
      <Spacer height="40px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="center" paddingHorizontal="10px">
          <div className="road-map-node completed"></div>
          <div className="road-map-line" style={{ height: "450px" }}></div>
          <div className="road-map-node in-progress"></div>
          <div className="road-map-line" style={{ height: "350px" }}></div>
          <div className="road-map-node"></div>
          <div className="road-map-line" style={{ height: "350px" }}></div>
          <div className="road-map-node"></div>{" "}
          <div
            className="road-map-dotted-line"
            style={{ height: "250px" }}
          ></div>
        </VerticalFlexContainer>
        <Spacer width="20px" />
        <VerticalFlexContainer backgroundColor="#222">
      <Spacer height="40px"/>
          <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
            <div className="road-map-description" align="left">  
              <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
                Q2 (2021) - Building the Drakkar & Recruiting the Raid Party
              </BaseText>
              <Spacer height="10px"/>
              <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Token Stealth Launched {Math.ceil(days/(1000*60*60*24))} Days Ago
              </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='10px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Website created
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Use Case Defined
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinMarketCap Listing Applied
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinGecko Listing Applied
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinHunt Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    BlockFolio Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    CoinSniper Listed
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    LiveCoinWatch Listed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Expanded Development Team
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Partnership with <a href="https://www.moonvalue.info/">moonvalue.info</a>
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Marketing Strategy Developed
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Operation Bifrost Defined
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                    Operation Mirror Mirror Launch
                  </BaseText>
                </Grid>
              </Grid>
            </div>
          </VerticalFlexContainer>
        <Spacer height="100px" />
          <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
            Q3 - Loading the Spoils
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Operation Bifrost Unmasked
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                API Data Collection R&D
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Storage and Maintenance R&D
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Live AMA’s Every week
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Celebrity Endorsements
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Marketing for Mass Adoption
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Website Version 2 Launch
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Back End Development for Operation Bifrost
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Listings on Small Exchanges
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
        <Spacer height="100px" backgroundColor="#222"/>
        <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
           Q4 - All Aboard the Drakkar 
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Mass Adoption
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Operation Bifrost Full Launch
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Addition of Services
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Liquidity Pool Addition
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  25% Expansion of Teams 
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Establish Offices in U.S.A and Hungary
                  </BaseText>
                </Grid>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Listings on Exchanges (Bitmart and Whitebit, ZBG, MXC)
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Donations to Charities of Community Choice (funds taken from revenue generated) 
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  Operation LowKey Launch
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
          <Spacer height="100px" backgroundColor="#222"/>
        <VerticalFlexContainer align="left" backgroundColor="black" paddingHorizontal="50px" paddingVertical="25px">
          <BaseText color="#22ffff" textAlign="center" fontWeight="bold" fontSize="18px">
          Q1 (2022) - Set Sail For Valhalla
          </BaseText>
          <Spacer height="10px"/>
          <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
          Grab your Ass...ets 
                  </BaseText>
                  <Spacer height="10px"/>
              <Grid autoFlow='column' columnGap='20px'>
                <Grid autoFlow='row' rowGap='8px'>
                <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                Further development of the Assgard Arsenal
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  The Assgard Community will vote on Priorities
                  </BaseText>
                  <BaseText color="#DEDEDE" textAlign="left" fontSize="15px">
                  2022 Roadmap Released 
                  </BaseText>
                </Grid>
              </Grid>
          </VerticalFlexContainer>
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
      <Spacer backgroundColor="#222" height="100px" />
    </MainSectionContainer>
  );
}