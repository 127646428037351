import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import Spacer from "../../components/Layout/Spacer";
import BaseText from "../../components/Text/BaseText";

export default function Mission() {
  return (
    <MainSectionContainer
      paddingHorizontal="10vw"
      height="auto"
      backgroundColor="#222"
    >
      <Spacer height="120px" />

      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
        id="Mission"
      >
        Mission
      </BaseText>
      <Spacer height="40px" />

      <BaseText color="#DEDEDE" fontSize="20px" textAlign="left">
        <i>
          Assgard is a community project that will give its users the ability to
          track their cryptocurrency purchases without the use of logging data
          to excel spreadsheets every time the user buys and sells a token.
          Assgard will be developed into a user interface that through the use
          data from BSCscan will automatically provide the user valuable
          information for tracking crypto assets without all the manual work.
        </i>
      </BaseText>
      {/* <br />
      <BaseText
        fontSize="20px"
        color="#DEDEDE"
        fontSize="20px"
        textAlign="left"
      >
        Assgard platform will have the following features:
      </BaseText>
      <Spacer height="10px" />
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Metamask and trust wallet integration
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Tally up how many BNB the user bought and sold across multiple
        transactions
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Tally up total number of tokens owned
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Poocoin Api integration to capture current price
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Display your current number of tokens multiplied by the current price
        so you can always see how much money you have in the tokens
      </BaseText>

      <br />
      <BaseText color="#DEDEDE" fontSize="20px" textAlign="left">
        This functionality will be available to users for small monthly fee paid
        in ASSGard tokens. The monthly fee will be applied to the following
        decided by the community:
      </BaseText>
      <Spacer height="10px" />
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Charitable donations
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Added Liquidity to Assgard
      </BaseText>
      <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
        - Community giveaways
      </BaseText> */}

      {/* <Spacer height="40px" /> */}
      <Spacer backgroundColor="#222" height="100px" />
    </MainSectionContainer>
  );
}
