import { useEffect, useState } from "react";
import "./App.css";
import Spacer from "./components/Layout/Spacer";
import Navbar from "./components/Navbar/Navbar";
import Banner from "./Sections/Banner/Banner";
import Trailer from "./Sections/Trailer/Trailer";
import Mission from "./Sections/Mission/Mission";
import Vision from "./Sections/Vision/Vision";
import ProductSuite from "./Sections/ProductSuite/ProductSuite";
import RoadMap from "./Sections/Roadmap/Roadmap";
import HowToBuy from "./Sections/HowToBuy/HowToBuy";
import Chart from "./Sections/Chart/Chart";
import Token from "./Sections/Token/Token";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Trailer />
      <Mission />
      <Vision />
      <ProductSuite />
      <Token />
      <RoadMap />
      <HowToBuy />
      <Chart />
      <Spacer backgroundColor="#222" height="100px" />
    </div>
  );
}

export default App;
