import React from "react";
import logo from "../../Assets/AssgardLogo.png";
import { useMediaQuery } from "@material-ui/core";
import "./Navbar.css";
import { useRef } from "react";
export default function Navbar() {
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const isMobileRef = useRef();
  React.useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  const [color, setColor] = React.useState(undefined);

  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        let href = document.querySelector(anchor.getAttribute("href"));
        console.log(window.pageYOffset + href?.getBoundingClientRect().top);
        window.scroll({
          top: window.pageYOffset + href?.getBoundingClientRect().top - 100,
          behavior: "smooth",
        });
      });
    });

    const interval = setInterval(() => {
      const diff = window.scrollY - window.innerHeight + 30;

      let navbar = document.getElementById("navbar");
      if (diff > 0 || isMobileRef.current === true) {
        setColor("#222");
      } else {
        setColor(undefined);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <nav
      id="navbar"
      className={`navbar navbar-expand-lg navbar-dark  fixed-top`}
      style={{
        backgroundColor: color,
      }}
    >
      <a className="navbar-brand ml-3" href="/">
        <img src={logo} className="logo-img" alt=""></img>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#Banner">
              Home
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Trailer">
              Trailer
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Mission">
              Mission
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Vision">
              Vision
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#ProductSuite">
              Product Suite
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Token">
              Token
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Roadmap">
              Roadmap
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#HowToBuy">
              How To Buy
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Chart">
              Chart
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active"
              href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7c52e19e4d350189bcdfb7f7a0868117e9f195e5&inputCurrency=BNB"
            >
              🚀 Buy now!
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
