import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import Spacer from "../../components/Layout/Spacer";
import BaseText from "../../components/Text/BaseText";
import Iframe from "react-iframe";
import { useState, useEffect } from 'react';

export default function Chart() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  return (
    <MainSectionContainer paddingHorizontal="10vw" height="auto" backgroundColor="#222">
       <BaseText
        color="#DEDEDE"
        fontSize="21px"
        fontWeight="bold"
        textAlign="center"
        id="Chart"
      >
        How Is ASSGARD Performing?
      </BaseText>

       <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="center"
      >
        Chart Data
      </BaseText>
      <Spacer height="40px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="center">
            <Iframe src="https://charts.bogged.finance/?token=0x7c52e19e4d350189bcdfb7f7a0868117e9f195e5" 
            width={windowDimensions.width-50}
            height="900px"
            frameborder="0" allowtransparency="true" allowfullscreen="true"
            overflow="auto">
            </Iframe>
        </VerticalFlexContainer>
        </HorizontalFlexContainer>
    </MainSectionContainer>
    );
}